/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'menu-button': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A1.5 1.5 0 011.5 0h8A1.5 1.5 0 0111 1.5v2A1.5 1.5 0 019.5 5h-8A1.5 1.5 0 010 3.5zM1.5 1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"/><path pid="1" d="M7.823 2.823l-.396-.396A.25.25 0 017.604 2h.792a.25.25 0 01.177.427l-.396.396a.25.25 0 01-.354 0M0 8a2 2 0 012-2h12a2 2 0 012 2v5a2 2 0 01-2 2H2a2 2 0 01-2-2zm1 3v2a1 1 0 001 1h12a1 1 0 001-1v-2zm14-1V8a1 1 0 00-1-1H2a1 1 0 00-1 1v2zM2 8.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0 4a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5"/>',
    },
});
